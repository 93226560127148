import { emitPanoramaCustomEvent } from '../util/custom-event';
import { getUuid } from '../util/uuid';

// Holds the current funnel interaction ID used for funnel workflow
let currentFunnelInteractionId: string;
// funnel interaction event type
const funnelInteractionType = 'funnelInteraction';
// funnel interaction error event type
const funnelInteractionErrorType = 'funnelInteractionError';

/**
 * Extract text/label from given DOM selector
 * @param {string | null | undefined} selector
 * @returns {string}
 */
const extractTextContent = (selector: string | null | undefined): string => {
    if (selector) {
        try {
            const nameIdentifier = document.querySelector(selector);
            if (nameIdentifier?.textContent) {
                return nameIdentifier.textContent;
            }
        } catch (err: any) {
            // log to Panorama as an internal metric
            emitPanoramaCustomEvent({
                eventSource: 'panoramaInternal',
                eventType: funnelInteractionErrorType,
                eventContext: 'extractTextContent',
                eventDetail: err ? err.toString() : '',
            });
        }
    }
    return '';
};

/**
 * Extract sub step number from DOM selectors
 * @param { string } subStepSelector
 * @param { string } subStepAllSelector
 * @returns { number | undefined }
 */
const extractSubStepNumberFromSelector = (
    subStepSelector: string,
    subStepAllSelector: string
): number | undefined => {
    let subStepNumber;
    try {
        const allSubSteps = document.querySelectorAll(subStepAllSelector);
        const currentSubStep = document.querySelector(subStepSelector);
        for (const [index, subStep] of allSubSteps.entries()) {
            if (subStep === currentSubStep) {
                subStepNumber = index + 1;
                break;
            }
        }
    } catch (err: any) {
        emitPanoramaCustomEvent({
            eventSource: 'panoramaInternal',
            eventType: funnelInteractionErrorType,
            eventContext: 'extractSubStepNumberFromSelector',
            eventDetail: err ? err.toString() : '',
        });
    }
    return subStepNumber;
};

/**
 * Extract step name or sub step name from given DOM selector
 * @param { string } selector
 * @returns { string }
 */
const extractName = (selector?: string): string => {
    let extractedName = '';
    try {
        if (selector) {
            extractedName = extractTextContent(selector);
        }
    } catch (err) {
        emitPanoramaCustomEvent({
            eventSource: 'panoramaInternal',
            eventType: funnelInteractionErrorType,
            eventContext: 'extractName',
            eventDetail: err ? err.toString() : '',
        });
    }
    return extractedName;
};

interface FunnelStart {
    // DOM selector for getting funnel name
    funnelNameSelector: string;
    // Total number of steps in current funnel
    totalFunnelSteps: number;
    // Type of funnel
    // single-page or multi-page(wizard)
    funnelType: string;
    // Optional step numbers in current funnel
    optionalStepNumbers?: number[];
    // Cloudscape funnel version
    funnelVersion?: string | number;
    // Cloudscape component version
    componentVersion?: string | number;
    // Cloudscape theme
    theme?: string;
    // Funnel interaction ID if passed by customers
    funnelInteractionId?: string;
}

/**
 * Emits a funnel start event
 * @param {FunnelStart} param0
 * @returns {string}
 */
export const funnelStart = ({
    funnelNameSelector,
    totalFunnelSteps,
    funnelType,
    optionalStepNumbers,
    funnelVersion,
    componentVersion,
    theme,
    funnelInteractionId,
}: FunnelStart): string => {
    currentFunnelInteractionId = funnelInteractionId ? funnelInteractionId : getUuid();
    const funnelName = extractTextContent(funnelNameSelector);
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelStart',
            funnelName,
            totalFunnelSteps,
            funnelType,
            optionalStepNumbers,
            funnelVersion,
            componentVersion,
            theme,
            funnelInteractionId: currentFunnelInteractionId,
        },
    });
    return currentFunnelInteractionId;
};

interface FunnelStepStart {
    // Number of step started
    stepNumber: number;
    // Name of the step
    stepName?: string;
    // DOM selector for getting step name
    stepNameSelector?: string;
    // DOM selector for finding all sub steps with in current step
    subStepAllSelector?: string;
    // Alternate way to provide total sub steps in current step
    totalSubSteps?: number;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emit a funnel step start event
 * @param {FunnelStepStart} param0
 */
export const funnelStepStart = ({
    stepNumber,
    stepName,
    stepNameSelector,
    subStepAllSelector,
    totalSubSteps,
    funnelInteractionId,
}: FunnelStepStart): void => {
    const extractedStepName = stepName || extractName(stepNameSelector);

    let totalNoOfSubSteps;
    if (totalSubSteps) {
        totalNoOfSubSteps = totalSubSteps;
    } else if (subStepAllSelector) {
        // find all sub steps with in given step
        const allSubSteps = document.querySelectorAll(subStepAllSelector);
        totalNoOfSubSteps = allSubSteps?.length;
    }

    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelStepStart',
            stepName: extractedStepName,
            stepNumber,
            totalSubSteps: totalNoOfSubSteps,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelSubStepStart {
    // Dom selector for sub step name
    subStepNameSelector?: string;
    // Dom selector for step name
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // name of the sub step
    subStepName?: string;
    // Current step number
    stepNumber: number;
    // Current sub step number
    subStepNumber?: number;
    // Current sub step DOM selector
    subStepSelector?: string;
    // Selector for all sub step in current step
    subStepAllSelector?: string;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits a funnel sub step start event
 * @param {FunnelSubStepStart} param0
 */
export const funnelSubStepStart = ({
    subStepNameSelector,
    stepNameSelector,
    stepName,
    subStepName,
    subStepNumber,
    stepNumber,
    subStepSelector,
    subStepAllSelector,
    funnelInteractionId,
}: FunnelSubStepStart): void => {
    const extractedSubStepName = subStepName || extractName(subStepNameSelector);
    const extractedStepName = stepName || extractName(stepNameSelector);

    let finalSubStepNumber;

    if (subStepNumber) {
        finalSubStepNumber = subStepNumber;
    } else if (subStepSelector && subStepAllSelector) {
        finalSubStepNumber = extractSubStepNumberFromSelector(subStepSelector, subStepAllSelector);
    }

    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelSubStepStart',
            subStepName: extractedSubStepName,
            subStepNumber: finalSubStepNumber,
            stepName: extractedStepName,
            stepNumber,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelStepError {
    // DOM selector for identifying label associated with errored form field
    fieldLabelSelector: string;
    // DOM selector for identifying error message associated with errored form field
    fieldErrorSelector: string;
    // Cloudscape component type for errored form field
    componentType?: string;
    // Step number at which error was thrown
    stepNumber?: number;
    // Dom selector for identifying Step name
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits a step error metric
 * @param {FunnelStepError} param0
 */
export const funnelStepError = ({
    fieldLabelSelector,
    fieldErrorSelector,
    componentType,
    stepNumber,
    stepNameSelector,
    stepName,
    funnelInteractionId,
}: FunnelStepError): void => {
    const fieldName = extractTextContent(fieldLabelSelector);
    const fieldError = extractTextContent(fieldErrorSelector);
    const extractedStepName = stepName || extractName(stepNameSelector);

    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelStepError',
            fieldName,
            fieldError,
            componentType,
            stepNumber,
            stepName: extractedStepName,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelSubStepError {
    // DOM selector for finding errored form field label text
    fieldLabelSelector: string;
    // DOm selector for capturing error message associated with errored form field
    fieldErrorSelector: string;
    // Cloudscape component type for form field element
    componentType?: string;
    // DOM selector for sub step
    subStepSelector?: string;
    // DOM selector for getting sub step name
    subStepNameSelector?: string;
    // name of the sub step
    subStepName?: string;
    // Funnel step number at which error happened
    stepNumber?: number;
    // Funnel sub step number at which error happened
    subStepNumber?: number;
    // DOM selector for getting step name
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // DOM selector for getting all sub step with in a step
    subStepAllSelector?: string;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits a funnel sub step error metric
 * @param {FunnelSubStepError} param0
 */
export const funnelSubStepError = ({
    fieldLabelSelector,
    fieldErrorSelector,
    componentType,
    subStepSelector,
    subStepNameSelector,
    subStepName,
    stepNumber,
    subStepNumber,
    stepNameSelector,
    stepName,
    subStepAllSelector,
    funnelInteractionId,
}: FunnelSubStepError): void => {
    const fieldName = extractTextContent(fieldLabelSelector);
    const fieldError = extractTextContent(fieldErrorSelector);
    const extractedSubStepName = subStepName || extractName(subStepNameSelector);
    const extractedStepName = stepName || extractName(stepNameSelector);

    let finalSubStepNumber;

    if (subStepNumber) {
        finalSubStepNumber = subStepNumber;
    } else if (subStepSelector && subStepAllSelector) {
        finalSubStepNumber = extractSubStepNumberFromSelector(subStepSelector, subStepAllSelector);
    }

    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelSubStepError',
            fieldName,
            fieldError,
            componentType,
            subStepName: extractedSubStepName,
            subStepNumber: finalSubStepNumber,
            stepName: extractedStepName,
            stepNumber,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelError {
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits a funnel error metric
 * Errors happening at funnel level, not specific to a step or sub step reported using this
 * @param { FunnelError } param0
 */
export const funnelError = ({ funnelInteractionId }: FunnelError): void => {
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelError',
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelStepComplete {
    // Completed step number
    stepNumber: number;
    // Completed step name selector
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // DOM selector for all sub steps in current step
    subStepAllSelector?: string;
    // Total number of sub steps
    totalSubSteps?: string;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits a step complete event
 * @param { FunnelStepComplete } param0
 */
export const funnelStepComplete = ({
    stepNumber,
    stepNameSelector,
    stepName,
    subStepAllSelector,
    totalSubSteps,
    funnelInteractionId,
}: FunnelStepComplete): void => {
    const extractedStepName = stepName || extractName(stepNameSelector);

    let totalNoOfSubSteps;
    if (totalSubSteps) {
        totalNoOfSubSteps = totalSubSteps;
    } else if (subStepAllSelector) {
        totalNoOfSubSteps = document.querySelectorAll(subStepAllSelector)?.length;
    }
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelStepComplete',
            stepNumber,
            stepName: extractedStepName,
            totalSubSteps: totalNoOfSubSteps,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelStepNavigation {
    // Type of navigation happened
    // prev, next, skip to step n
    navigationType: string;
    // Step number at which user was before navigating
    stepNumber: number;
    // DOM selector for current step name
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // DOM selector for getting all sub step in current step
    subStepAllSelector?: string;
    // Total number of sub steps
    totalSubSteps?: number;
    // Step number to which is user is navigating to
    destinationStepNumber: number;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits a funnel step navigation event
 * Used primarily for Wizard (multi-page) funnels
 * @param { FunnelStepNavigation } param0
 */
export const funnelStepNavigation = ({
    navigationType,
    stepNumber,
    stepNameSelector,
    stepName,
    subStepAllSelector,
    totalSubSteps,
    destinationStepNumber,
    funnelInteractionId,
}: FunnelStepNavigation): void => {
    const extractedStepName = stepName || extractName(stepNameSelector);
    let totalNoOfSubSteps;
    if (totalSubSteps) {
        totalNoOfSubSteps = totalSubSteps;
    } else if (subStepAllSelector) {
        totalNoOfSubSteps = document.querySelectorAll(subStepAllSelector)?.length;
    }
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelStepNavigation',
            navigationType,
            originStepNumber: stepNumber,
            originStepName: extractedStepName,
            totalSubSteps: totalNoOfSubSteps,
            destinationStepNumber,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelSubStepComplete {
    // DOM selector for sub step name
    subStepNameSelector?: string;
    // name of the sub step
    subStepName?: string;
    // Funnel current step number for which sub step completed
    stepNumber: number;
    // Current sub step number
    subStepNumber?: number;
    // DOM selector for sub step
    subStepSelector?: string;
    // DOM selector for step name
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // DOM selector for all sub steps in current step
    subStepAllSelector?: string;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits funnel sub step complete metric
 * @param { FunnelSubStepComplete } param0
 */
export const funnelSubStepComplete = ({
    subStepNameSelector,
    subStepName,
    subStepNumber,
    stepNumber,
    subStepSelector,
    stepNameSelector,
    stepName,
    subStepAllSelector,
    funnelInteractionId,
}: FunnelSubStepComplete): void => {
    const extractedSubStepName = subStepName || extractName(subStepNameSelector);
    const extractedStepName = stepName || extractName(stepNameSelector);

    let finalSubStepNumber;

    if (subStepNumber) {
        finalSubStepNumber = subStepNumber;
    } else if (subStepSelector && subStepAllSelector) {
        finalSubStepNumber = extractSubStepNumberFromSelector(subStepSelector, subStepAllSelector);
    }
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelSubStepComplete',
            subStepName: extractedSubStepName,
            subStepNumber: finalSubStepNumber,
            stepName: extractedStepName,
            stepNumber,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelComplete {
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits a funnel complete event
 * emitted when user successfully submitted funnel workflow using primary button click like submit, launch
 * @param { FunnelComplete } param0
 */
export const funnelComplete = ({ funnelInteractionId }: FunnelComplete): void => {
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelComplete',
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelSuccessful {
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emitted when funnel is sucessful
 * When current funnel complete is unmounted with out any errors
 * @param { FunnelSuccessful } param0
 */
export const funnelSuccessful = ({ funnelInteractionId }: FunnelSuccessful): void => {
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelSuccessful',
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface FunnelCancelled {
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emitted when a funnel workflow is cancelled
 * @param { FunnelCancelled } param0
 */
export const funnelCancelled = ({ funnelInteractionId }: FunnelCancelled): void => {
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'funnelCancelled',
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface HelpPanelInteracted {
    // DOM selector for getting help panel content interacted
    elementSelector: string;
    // Current step number where link is interacted
    stepNumber: number;
    // Step name DOM selector
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // Sub step DOM selector
    subStepSelector?: string;
    // Sub step number
    subStepNumber?: number;
    // DOM selector for sub step name
    subStepNameSelector?: string;
    // name of the sub step
    subStepName?: string;
    // DOM selector for all sub steps in current step
    subStepAllSelector?: string;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emits help panel interaction metric when user enhances with help panel links
 * @param { HelpPanelInteracted } param0
 */
export const helpPanelInteracted = ({
    elementSelector,
    stepNumber,
    stepNameSelector,
    stepName,
    subStepSelector,
    subStepNumber,
    subStepNameSelector,
    subStepName,
    subStepAllSelector,
    funnelInteractionId,
}: HelpPanelInteracted): void => {
    const helpContent = extractTextContent(elementSelector);
    const extractedSubStepName = subStepName || extractName(subStepNameSelector);
    const extractedStepName = stepName || extractName(stepNameSelector);

    let finalSubStepNumber;

    if (subStepNumber) {
        finalSubStepNumber = subStepNumber;
    } else if (subStepSelector && subStepAllSelector) {
        finalSubStepNumber = extractSubStepNumberFromSelector(subStepSelector, subStepAllSelector);
    }

    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'helpPanelInteracted',
            helpContent,
            stepNumber,
            stepName: extractedStepName,
            subStepNumber: finalSubStepNumber,
            subStepName: extractedSubStepName,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};

interface ExternalLinkInteracted {
    // DOM selector for getting interacted external link content
    elementSelector: string;
    // Current step number where link is interacted
    stepNumber: number;
    // Step name DOM selector
    stepNameSelector?: string;
    // name of the step
    stepName?: string;
    // Sub step DOM selector
    subStepSelector?: string;
    // Sub step number
    subStepNumber?: number;
    // DOM selector for sub step name
    subStepNameSelector?: string;
    // name of the sub step
    subStepName?: string;
    // DOM selector for all sub steps in current step
    subStepAllSelector?: string;
    // Funnel interaction ID used as part of funnel start event
    funnelInteractionId?: string;
}
/**
 * Emitted when an external link is interacted by users
 * @param { ExternalLinkInteracted } param0
 */
export const externalLinkInteracted = ({
    elementSelector,
    stepNumber,
    stepNameSelector,
    stepName,
    subStepSelector,
    subStepNumber,
    subStepNameSelector,
    subStepName,
    subStepAllSelector,
    funnelInteractionId,
}: ExternalLinkInteracted): void => {
    const linkText = extractTextContent(elementSelector);
    const extractedSubStepName = subStepName || extractName(subStepNameSelector);
    const extractedStepName = stepName || extractName(stepNameSelector);

    let finalSubStepNumber;

    if (subStepNumber) {
        finalSubStepNumber = subStepNumber;
    } else if (subStepSelector && subStepAllSelector) {
        finalSubStepNumber = extractSubStepNumberFromSelector(subStepSelector, subStepAllSelector);
    }
    emitPanoramaCustomEvent({
        eventType: funnelInteractionType,
        eventInteractionMetaData: {
            funnelEventType: 'externalLinkInteracted',
            linkText,
            stepNumber,
            stepName: extractedStepName,
            subStepNumber: finalSubStepNumber,
            subStepName: extractedSubStepName,
            funnelInteractionId: funnelInteractionId || currentFunnelInteractionId,
        },
    });
};
